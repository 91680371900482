import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function TableSimplePreview(props) {
  const { TableSimple } = props

  return (
    <>
      <CopyComponent
        copyToClipboardCode={TableSimple}
        componentName="TableSimple"
      >
        {/*<!-- Component: Simple Table --> */}
        <div className="w-full overflow-x-auto">
          <table
            className="w-full text-left border border-collapse rounded border-slate-200 sm:border-separate"
            cellspacing="0"
          >
            <tbody>
              <tr>
                <th
                  scope="col"
                  className="h-12 px-6 text-sm font-medium border-l bg-slate-100 stroke-slate-700 text-slate-700 first:border-l-0"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="h-12 px-6 text-sm font-medium border-l bg-slate-100 stroke-slate-700 text-slate-700 first:border-l-0"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="h-12 px-6 text-sm font-medium border-l bg-slate-100 stroke-slate-700 text-slate-700 first:border-l-0"
                >
                  Company
                </th>
                <th
                  scope="col"
                  className="h-12 px-6 text-sm font-medium border-l bg-slate-100 stroke-slate-700 text-slate-700 first:border-l-0"
                >
                  Role
                </th>
                <th
                  scope="col"
                  className="h-12 px-6 text-sm font-medium border-l bg-slate-100 stroke-slate-700 text-slate-700 first:border-l-0"
                >
                  Username
                </th>
              </tr>
              <tr>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Ayub Salas
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Designer
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Carroll Group
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Member
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  salas_a
                </td>
              </tr>
              <tr>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Agnes Sherman
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Developer
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Apple
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Admin
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  shermanagnes
                </td>
              </tr>
              <tr>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Jemma Cummings
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Senior Designer
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  20goto10
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Member
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  jemmaC
                </td>
              </tr>
              <tr>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Jimi Cardenas
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Copywriter
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Wind-UI
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Owner
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  cardenasji
                </td>
              </tr>
              <tr>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Mateusz Tucker
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Project Manager
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Tailwindui
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  Member
                </td>
                <td className="h-12 px-6 text-sm transition duration-300 border-t border-l border-slate-200 stroke-slate-500 text-slate-500 first:border-l-0 ">
                  mt
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/*<!-- End Simple Table --> */}
      </CopyComponent>
    </>
  )
}
