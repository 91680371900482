import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"
import TablesHeroComponent from "../../../library/components/table/react/_preview/TablesHeroComponent"

// Preview
import TableSimplePreview from "../../../library/components/table/react/_preview/simple"
import TableResponsivePreview from "../../../library/components/table/react/_preview/responsive"
import TableSimpleWithFooterPreview from "../../../library/components/table/react/_preview/simple_with_footer"
import TableStripedPreview from "../../../library/components/table/react/_preview/striped"
import TableWithHoverPreview from "../../../library/components/table/react/_preview/with_hover"
import TableBorderlessPreview from "../../../library/components/table/react/_preview/borderless"
import TableUnderlinePreview from "../../../library/components/table/react/_preview/underline"
import TableCompactPreview from "../../../library/components/table/react/_preview/compact"

// All
const TableSimpleJsx = require("!!raw-loader!../../../library/components/table/react/simple.jsx")
const TableSimpleHTML = require("!!raw-loader!../../../library/components/table/html/simple.html")

const TableResponsiveJsx = require("!!raw-loader!../../../library/components/table/react/responsive.jsx")
const TableResponsiveHTML = require("!!raw-loader!../../../library/components/table/html/responsive.html")

const TableSimpleWithFooterJsx = require("!!raw-loader!../../../library/components/table/react/simple_with_footer.jsx")
const TableSimpleWithFooterHTML = require("!!raw-loader!../../../library/components/table/html/simple_with_footer.html")

const TableStripedJsx = require("!!raw-loader!../../../library/components/table/react/striped.jsx")
const TableStripedHTML = require("!!raw-loader!../../../library/components/table/html/striped.html")

const TableWithHoverJsx = require("!!raw-loader!../../../library/components/table/react/with_hover.jsx")
const TableWithHoverHTML = require("!!raw-loader!../../../library/components/table/html/with_hover.html")

const TableBorderlessJsx = require("!!raw-loader!../../../library/components/table/react/borderless.jsx")
const TableBorderlessHTML = require("!!raw-loader!../../../library/components/table/html/borderless.html")

const TableUnderlineJsx = require("!!raw-loader!../../../library/components/table/react/underline.jsx")
const TableUnderlineHTML = require("!!raw-loader!../../../library/components/table/html/underline.html")

const TableCompactJsx = require("!!raw-loader!../../../library/components/table/react/compact.jsx")
const TableCompactHTML = require("!!raw-loader!../../../library/components/table/html/compact.html")

export default function TablesPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "TableSimple",
      title: "Simple Table",
      active_tab: 1,
    },
    {
      component_name: "TableResponsive",
      title: "Responsive",
      active_tab: 1,
    },
    {
      component_name: "TableSimpleWithFooter",
      title: "With Footer",
      active_tab: 1,
    },
    {
      component_name: "TableStriped",
      title: "Striped",
      active_tab: 1,
    },
    {
      component_name: "TableWithHover",
      title: "With hover state",
      active_tab: 1,
    },

    {
      component_name: "BorderlessTable",
      title: "Borderless",
      active_tab: 1,
    },
    {
      component_name: "TableUnderline",
      title: "Underline",
      active_tab: 1,
    },
    {
      component_name: "TableCompact",
      title: "Compact",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Tables - WindUI Component Library"
        ogtitle="Tailwind CSS Tables - WindUI Component Library"
        description="Table components, present information in a two-dimensional table comprised of rows and columns of cells containing data. Built with Tailwind CSS by WindUI."
        ogdescription="Table components, present information in a two-dimensional table comprised of rows and columns of cells containing data. Built with Tailwind CSS by WindUI."
        url="components/tables/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Tables, Tables, Table Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Tables</h1>
          <p>
            The table element represents tabular data — that is, the information
            presented in a two-dimensional table comprised of rows and columns
            of cells containing data.
          </p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16 [&>*]:w-full">
              <TablesHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="TableSimple">Simple Table</h3>

          <PreviewBlock
            id="TableSimple"
            HtmlComponent={TableSimpleHTML.default}
            JsxComponent={TableSimpleJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <TableSimplePreview
                TableSimple={
                  activeTabs[0].active_tab === 1
                    ? TableSimpleHTML.default
                    : TableSimpleJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="TableResponsive">Responsive Table</h3>
          <p>Resize your browser window to the table on small screens.</p>
          <PreviewBlock
            id="TableResponsive"
            HtmlComponent={TableResponsiveHTML.default}
            JsxComponent={TableResponsiveJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <TableResponsivePreview
                TableResponsive={
                  activeTabs[1].active_tab === 1
                    ? TableResponsiveHTML.default
                    : TableResponsiveJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="TableSimpleWithFooter">Simple Table with Footer</h3>

          <PreviewBlock
            id="TableSimpleWithFooter"
            HtmlComponent={TableSimpleWithFooterHTML.default}
            JsxComponent={TableSimpleWithFooterJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <TableSimpleWithFooterPreview
                TableSimpleWithFooter={
                  activeTabs[2].active_tab === 1
                    ? TableSimpleWithFooterHTML.default
                    : TableSimpleWithFooterJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="TableStriped">Striped Table</h3>

          <PreviewBlock
            id="TableStriped"
            HtmlComponent={TableStripedHTML.default}
            JsxComponent={TableStripedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <TableStripedPreview
                TableStriped={
                  activeTabs[3].active_tab === 1
                    ? TableStripedHTML.default
                    : TableStripedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="TableWithHover">Table with Hover State</h3>

          <PreviewBlock
            id="TableWithHover"
            HtmlComponent={TableWithHoverHTML.default}
            JsxComponent={TableWithHoverJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full ">
              <TableWithHoverPreview
                TableWithHover={
                  activeTabs[4].active_tab === 1
                    ? TableWithHoverHTML.default
                    : TableWithHoverJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="BorderlessTable">Borderless Table</h3>

          <PreviewBlock
            id="BorderlessTable"
            HtmlComponent={TableBorderlessHTML.default}
            JsxComponent={TableBorderlessJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <TableBorderlessPreview
                BorderlessTable={
                  activeTabs[5].active_tab === 1
                    ? TableBorderlessHTML.default
                    : TableBorderlessJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="TableUnderline">Underline Table</h3>

          <PreviewBlock
            id="TableUnderline"
            HtmlComponent={TableUnderlineHTML.default}
            JsxComponent={TableUnderlineJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <TableUnderlinePreview
                TableUnderline={
                  activeTabs[6].active_tab === 1
                    ? TableUnderlineHTML.default
                    : TableUnderlineJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="CompactTable">Compact Table</h3>

          <PreviewBlock
            id="TableCompact"
            HtmlComponent={TableCompactHTML.default}
            JsxComponent={TableCompactJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 [&>*]:w-full">
              <TableCompactPreview
                TableCompact={
                  activeTabs[7].active_tab === 1
                    ? TableCompactHTML.default
                    : TableCompactJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Data tables display information in a grid-like format of rows and
            columns. They organize information in a way that’s easy to scan so
            that users can look for patterns and develop insights from data.
          </p>
          <p>Data tables can contain:</p>
          <ul>
            <li>Interactive components (such as chips, buttons, or menus)</li>
            <li>Non-interactive elements (such as badges)</li>
            <li>Tools to query and manipulate data</li>
          </ul>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>A table consists of the following elements</p>
          <ul>
            <li>
              <code>&lt;table&gt;</code> : Defines the table
            </li>
            <li>
              <code>&lt;tbody&gt;</code> : Groups the body content in a table
            </li>
            <li>
              <code>&lt;tr&gt;</code> : Defines rows in a table
            </li>
            <li>
              <code>&lt;th&gt;</code> : Defines a header cell in a table
            </li>
            <li>
              <code>&lt;td&gt;</code> : Defines a cell in a table
            </li>

            <li>
              <code>&lt;tfoot&gt;</code> : Groups the footer content in a table
            </li>
          </ul>
          <p>Tables come in 2 sizes:</p>
          <ul>
            <li>
              <strong>Normal:</strong> rows have 3rem height (48px) and 1.5rem
              (24px) horizontal padding.
              <strong>Compact:</strong> rows have 2.5rem height (40px) and 1rem
              (16px) horizontal padding.
            </li>
          </ul>
          <p>
            Wrap a table in a{" "}
            <code>
              &lt;div className=&quot;w-full overflow-x-auto&quot;&gt;
            </code>{" "}
            to make it scroll so that users can view its data on small screens
            or use the responsive table instead.
          </p>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              Tables with one header and simple data are fairly accessible out
              of the box and may not need additional accessibility updates.
              Always use the simplest table configuration possible.{" "}
            </li>
            <li>
              When your tables get more complex, use the <code>&lt;th&gt;</code>{" "}
              element to identify the header cells by adding a scope attribute.
              For header rows use <code>&lt;th scope="row"&gt;</code>. For
              header columns use <code>&lt;th scope="col"&gt;</code>
            </li>
            <li>
              Add the optional <code>&lt;caption&gt;</code> element before the
              table content to give users more information on the table
              contents.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
