import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function TableSimpleWithFooterPreview(props) {
  const { TableSimpleWithFooter } = props

  return (
    <>
      <CopyComponent
        copyToClipboardCode={TableSimpleWithFooter}
        componentName="TableSimpleWithFooter"
      >
        {/*<!-- Component: Simple with footer --> */}
        <div className="w-full overflow-x-auto">
          <table
            className="w-full border-separate rounded border border-slate-200 text-left"
            cellspacing="0"
          >
            <tbody>
              <tr>
                <th
                  scope="col"
                  className="h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-center text-sm font-medium text-slate-700 first:border-l-0"
                >
                  No
                </th>
                <th
                  scope="col"
                  className="h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0"
                >
                  Product Name
                </th>
                <th
                  scope="col"
                  className="h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0"
                >
                  Color
                </th>
                <th
                  scope="col"
                  className="h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0"
                >
                  Price
                </th>
              </tr>
              <tr>
                <th
                  scope="row"
                  className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-center text-sm text-slate-500 transition duration-300 first:border-l-0 "
                >
                  1
                </th>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  Apple Watch
                </td>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  White
                </td>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  Wearables
                </td>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  $299
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-center text-sm text-slate-500 transition duration-300 first:border-l-0 "
                >
                  2
                </th>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  Macbook pro 16"
                </td>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  Silver
                </td>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  Laptops
                </td>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  $2999
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-center text-sm text-slate-500 transition duration-300 first:border-l-0 "
                >
                  3
                </th>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  Macbook pro 13"
                </td>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  Space Gray
                </td>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  Laptops
                </td>
                <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0 ">
                  $1999
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td
                  className="h-12 border-t border-l stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0"
                  colspan="3"
                ></td>
                <td className="h-12 border-t border-l stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 ">
                  Total
                </td>
                <td className="h-12 border-t border-l stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 ">
                  $5297
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        {/*<!-- End Simple with footer --> */}
      </CopyComponent>
    </>
  )
}
