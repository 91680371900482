import React from "react"

export default function TablesHeroComponent() {
  return (
    <>
      {/*<!-- Responsive table --> */}
      <table
        className="w-full text-left border border-separate rounded shadow-md border-slate-200 shadow-slate-100"
        cellspacing="0"
      >
        <tbody>
          <tr>
            <th
              scope="col"
              className="hidden h-12 px-6 text-sm font-medium border-l bg-emerald-50 stroke-slate-700 text-slate-700 first:border-l-0 sm:table-cell"
            >
              Name
            </th>
            <th
              scope="col"
              className="hidden h-12 px-6 text-sm font-medium border-l bg-emerald-50 stroke-slate-700 text-slate-700 first:border-l-0 sm:table-cell"
            >
              Title
            </th>
            <th
              scope="col"
              className="hidden h-12 px-6 text-sm font-medium border-l bg-emerald-50 stroke-slate-700 text-slate-700 first:border-l-0 sm:table-cell"
            >
              Company
            </th>
            <th
              scope="col"
              className="hidden h-12 px-6 text-sm font-medium border-l bg-emerald-50 stroke-slate-700 text-slate-700 first:border-l-0 sm:table-cell"
            >
              Role
            </th>
            <th
              scope="col"
              className="hidden h-12 px-6 text-sm font-medium border-l bg-emerald-50 stroke-slate-700 text-slate-700 first:border-l-0 sm:table-cell"
            >
              Username
            </th>
          </tr>
          <tr className="block border-b border-slate-200 last:border-b-0 sm:table-row sm:border-none">
            <td
              data-th="Name"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Ayub Salas
            </td>
            <td
              data-th="Title"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Designer
            </td>
            <td
              data-th="Company"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Carroll Group
            </td>
            <td
              data-th="Role"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Member
            </td>
            <td
              data-th="Username"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              salas_a
            </td>
          </tr>
          <tr className="block border-b border-slate-200 last:border-b-0 sm:table-row sm:border-none">
            <td
              data-th="Name"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Agnes Sherman
            </td>
            <td
              data-th="Title"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Developer
            </td>
            <td
              data-th="Company"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Apple
            </td>
            <td
              data-th="Role"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Admin
            </td>
            <td
              data-th="Username"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              shermanagnes
            </td>
          </tr>
          <tr className="block border-b border-slate-200 last:border-b-0 sm:table-row sm:border-none">
            <td
              data-th="Name"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Jemma Cummings
            </td>
            <td
              data-th="Title"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Senior Designer
            </td>
            <td
              data-th="Company"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              20goto10
            </td>
            <td
              data-th="Role"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Member
            </td>
            <td
              data-th="Username"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              jemmaC
            </td>
          </tr>
          <tr className="block border-b border-slate-200 last:border-b-0 sm:table-row sm:border-none">
            <td
              data-th="Name"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Jimi Cardenas
            </td>
            <td
              data-th="Title"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Copywriter
            </td>
            <td
              data-th="Company"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Wind-UI
            </td>
            <td
              data-th="Role"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Owner
            </td>
            <td
              data-th="Username"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              cardenasji
            </td>
          </tr>
          <tr className="block border-b border-slate-200 last:border-b-0 sm:table-row sm:border-none">
            <td
              data-th="Name"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Mateusz Tucker
            </td>
            <td
              data-th="Title"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Project Manager
            </td>
            <td
              data-th="Company"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Tailwindui
            </td>
            <td
              data-th="Role"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              Member
            </td>
            <td
              data-th="Username"
              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
            >
              mt
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
